const servers = [
  {
    "name": "CraftLime",
    "ip": "mc.craftlime.net",
    "logo": "swLogo.png",
    "description": "Founded in 2022, CraftLime has quickly risen to become the #1 Towny server, offering a thriving and dynamic community for players who love building, trading, and creating towns.",
    "social": {
      "discord": "https://discord.gg/lime",
      "instagram": "https://instagram.com/craftlimenw"
    },
    "logoBgColor": "#FFD700",
    "buttonColor": "#FFD700",
    "textColor": "#000000",
    "webSite": "https://craftlime.net"
  },
  {
    "name": "LuneCraft",
    "ip": "mc.lunecraft.net",
    "logo": "lune.svg",
    "description": "Acquired in 2024, LuneCraft offers a genuine and immersive Towny gameplay that appeals to both seasoned players and newcomers.",
    "social": {
      "discord": "https://discord.gg/lunecraft",
      "instagram": "https://instagram.com/lunecraftmc"
    },
    "logoBgColor": "#060A15",
    "buttonColor": "#060A15",
    "textColor": "#FFFFFF",
    "webSite": "https://lunecraft.net"
  },
  {
    "name": "Vanilya",
    "ip": "mc.vanilya.xyz",
    "logo": "vanilya.png",
    "description": "Acquired in 2024, Vanilya offers a relaxed blend of survival and towny gameplay, perfect for those looking to unwind and explore at their own pace. Whether you're building towns, enjoying the thrill of survival mode, or challenging yourself on our Skyblock server, Vanilya provides a laid-back atmosphere where creativity and fun come first.",
    "social": {
      "discord": "https://discord.gg/vanilyaxyz",
      "instagram": "https://instagram.com/vanilyaxyz"
    },
    "logoBgColor": "#40B0C0",
    "buttonColor": "#40B0C0",
    "textColor": "#FFFFFF",
    "webSite": "https://vanilya.xyz"
  },
  {
    "name": "LegendCraftTR",
    "ip": "play.legendcrafttr.com",
    "logo": "lgtr.png",
    "description": "Acquired in 2024, LegendCraftTR has been a cornerstone of the Turkish Minecraft community, bringing players together for years. Our server is dedicated to providing a rich and immersive gameplay experience that caters to all types of players, from newcomers to seasoned veterans.",
    "social": {
      "discord": "https://discord.gg/lgtr",
      "instagram": "#"
    },
    "logoBgColor": "#B73939",
    "buttonColor": "#B73939",
    "textColor": "#FFFFFF",
    "webSite": "https://legendcrafttr.net"
  },
  {
    "name": "MineLama",
    "ip": "play.minelama.net",
    "logo": "Lama3.gif",
    "description": "MineLama was born as an idea in 2023, envisioned as an immersive Survival server experience. Though initially shelved, the concept continued to evolve. Now, in 2024, former CraftLime team members have reunited to bring this dream to life. More than just a Minecraft server, MineLama is a blend of nostalgia, expertise, and a fresh start for a unique Towny adventure. Get ready for an unforgettable journey in the Minecraft world!",
    "social": {
      "discord": "https://discord.gg/minelama",
      "instagram": "https://instagram.com/minelamanw"
    },
    "logoBgColor": "#8BB877",
    "buttonColor": "#8BB877",
    "textColor": "#000000",
    "webSite": "https://minelama.com"
  },
  {
    "name": "Craftity",
    "ip": "mc.craftity.net",
    "logo": "craftity.png",
    "description": "CraftityNet is a Turkish Minecraft server offering both Java and Bedrock support, featuring popular game modes like Skyblock and Towny. It provides a community-driven environment where players can engage in diverse gameplay experiences across supported versions 1.16.5 to 1.21",
    "social": {
      "discord": "https://discord.gg/craftity",
      "instagram": "https://instagram.com/craftity"
    },
    "logoBgColor": "#9232e9",
    "buttonColor": "#9232e9",
    "textColor": "#ffffff",
    "webSite": "https://craftity.net"
  }
];

export const comingSoonServers = [
  {
    name: "CraftNovus",
    ip: "mc.craftnovus.com",
    logo: "craftnovus.png",
    description: "Get ready for an extraordinary Minecraft experience with CraftNovus! Our upcoming server combines classic gameplay elements with innovative features, creating a unique blend of adventure and community interaction. Join us in this exciting new chapter of Minecraft multiplayer gaming.",
    expectedDate: "December 2024",
    buttonColor: "#6B4EE6",
    textColor: "#FFFFFF",
    logoBgColor: "#2D1B69"
  }
];

export default servers;
